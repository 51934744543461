// Para llamar a la función LimitarTexto en el componente que lo deseeemos primero debemos importarla
// Esta funcion valida que el usuario solo captura cierto limite de caracteres y solo texto
// * @param {HTMLInputElement} input - El input de texto al que se le aplicará la limitación.
//  * @param {number} limite - La cantidad máxima de caracteres permitidos.

export function LimitarTexto(input, limite) {
  // Expresión regular que permite letras, espacios, caracteres acentuados y la letra "ñ"
  const caracteresPermitidos = /^[a-zA-ZáéíóúñÁÉÍÓÚ\s]*$/;

  if (!caracteresPermitidos.test(input.value)) {
    // Si el valor no cumple con la expresión regular, se eliminan los caracteres no permitidos
    input.value = input.value.replace(/[^\sa-zA-ZáéíóúñÁÉÍÓÚ]/g, "");
  }

  if (input.value.length > limite) {
    // Si el valor excede el límite, se trunca
    input.value = input.value.substring(0, limite);
  }
}

export function LimitarTexto_espacios(input, limite) {
   // Expresión regular que permite letras (mayúsculas y minúsculas), acentos, ñ y espacios
   const caracteresPermitidos = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/;

   if (!caracteresPermitidos.test(input.value)) {
     // Si el valor no cumple con la expresión regular, se eliminan los caracteres no permitidos
     input.value = input.value.replace(/[^\sa-zA-ZáéíóúÁÉÍÓÚñÑ]/g, "");
   }

   if (input.value.length > limite) {
     // Si el valor excede el límite, se trunca
     input.value = input.value.substring(0, limite);
   }
 }

export function LimitarTexto2(input, limite) {
  // Expresión regular que permite letras, espacios, caracteres acentuados y la letra "ñ"
  const caracteresPermitidos =
    /^[a-zA-ZáéíóúñÁÉÍÓÚ\s.@#$%^&*_+=()[\]{}|\\;:'",.<>/?!~-]*$/;

  if (!caracteresPermitidos.test(input.value)) {
    // Si el valor no cumple con la expresión regular, se eliminan los caracteres no permitidos
    input.value = input.value.replace(
      /[^\sa-zA-ZáéíóúñÁÉÍÓÚ.@#$%^&*_+=()[\]{}|\\;:'",.<>/?!~-]/g,
      ""
    );
  }

  if (input.value.length > limite) {
    // Si el valor excede el límite, se trunca
    input.value = input.value.substring(0, limite);
  }
}

export function LimitarTextoYNumero(input, limite) {
  // Expresión regular que permite letras, espacios, caracteres acentuados y la letra "ñ"
  const caracteresPermitidos = /^[a-zA-ZáéíóúñÁÉÍÓÚ\s\d]*$/;

  if (!caracteresPermitidos.test(input.value)) {
    // Si el valor no cumple con la expresión regular, se eliminan los caracteres no permitidos
    input.value = input.value.replace(/[^\s\da-zA-ZáéíóúñÁÉÍÓÚ]/g, "");
  }

  if (input.value.length > limite) {
    // Si el valor excede el límite, se trunca
    input.value = input.value.substring(0, limite);
  }
}

// Para usar la función en un input de texto, simplemente debes llamarla
// dentro del evento onKeyUp del input, pasando
// como argumentos el elemento input y la cantidad máxima de caracteres permitidos.
{
  /* <div>
<input
  type="text"
  maxLength={15}
  onKeyUp={(e) => LimitarTexto(e.target, 15)}
/>
</div> */
}

//Validacion de solo numeros
export function LimitarDigitos(input, limite) {
  // Expresión regular que solo permite números
  const regex = /[^0-9]/g;
  input.value = input.value.replace(regex, "");
  if (input.value.length > limite) {
    input.value = input.value.substring(0, limite);
  }
}

//Valida un correo electronico
export function validarCorreo(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (regex.test(email)) {
    return true;
  } else {
    return false;
  }
}
